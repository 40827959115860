<template>
  <div class="container">
    <h1>Бриф Actid.Cards</h1>
    <template v-if="dataSent">
      <ThankYou />
    </template>
    <template v-else>
      <div class="question">
        <h3>Основные</h3>
        <p>
          <label>Название заведения:</label>
          <input v-model="form.shop_name" type="text" />
        </p>
        <p>
          <label>Email будущего владельца личного кабинета:</label>
          <input v-model="form.lk_email" type="email" />
        </p>
        <p>
          <label>POS или CRM система:</label>
          <select v-model="form.pos">
            <option value="Poster">Poster</option>
            <option value="IIKO">IIKO</option>
            <option value="QuickRest">QuickResto</option>
            <option value="no">Нет системы</option>
            <option value="other">Другая</option>
          </select>
        </p>
      </div>
      <div class="question">
        <h3>Информация для гостей</h3>
        <p>
          <label>Ссылка в Instagram:</label>
          <input v-model="form.instagram" type="text" />
        </p>
        <p>
          <label>Ссылка на Яндекс.Картах:</label>
          <input v-model="form.yandex_maps" type="text" />
        </p>
        <p>
          <label>Номер телефона для бронирования:</label>
          <input v-model="form.booking_phone" type="text" />
        </p>
        <div class="row">
          <div class="col">
            <p>
              <label>Время работы:</label>
              <textarea v-model="form.timing" rows="3"></textarea>
            </p>
          </div>
          <div class="col">
            <label>Например,</label>
            <div class="example">
              ПН-ПТ: 10:00-23:00<br />
              СБ-ВС: 12:00-02:00
            </div>
          </div>
        </div>
      </div>
      <div class="question">
        <h3>Система лояльности</h3>
        <fieldset>
          <legend>Тип лояльности</legend>
          <div class="radio">
            <input
              v-model="form.loyality"
              type="radio"
              name="radio"
              id="radio-option1"
              value="bonus"
            />
            <label for="radio-option1">Бонусы (накопительная с кешбеком)</label>
          </div>
          <div class="radio">
            <input
              v-model="form.loyality"
              type="radio"
              name="radio"
              id="radio-option2"
              value="discount"
            />
            <label for="radio-option2">Скидки (скидка при оплате)</label>
          </div>
        </fieldset>
        <div class="row">
          <div class="col">
            <p>
              <label>Опишите программу:</label>
              <textarea v-model="form.loyality_desc" rows="3"></textarea>
            </p>
          </div>
          <div class="col">
            <label>Например,</label>
            <div class="example">
              <template v-if="form.loyality == 'bonus'">
                3% - начальный кешбек
                <br />
                5% - кешбек при сумме трат от 50.000 рублей
                <br />
                10% - кешбек при сумме трат от 100.000 рублей
              </template>
              <template v-else>
                3% - начальная скидка
                <br />
                5% - скидка при сумме трат от 50.000 рублей
                <br />
                10% - скидка при сумме трат от 100.000 рублей
              </template>
            </div>
          </div>
        </div>
        <p v-if="form.loyality == 'bonus'">
          <label>Процент оплаты бонусами при закрытии счёта:</label>
          <input v-model="form.loyality_discount" type="number" />
        </p>
      </div>
      <div class="question">
        <h3>Карты</h3>
        <p>
          <label
            >Ссылка на логотип (в любом из форматов <b>.png, .pdf, .svg</b> и
            <b>с прозрачным фоном</b>):</label
          >
          <input v-model="form.logo_url" type="url" />
        </p>
        <div class="row">
          <div class="col">
            <label
              >Выберите один или несколько дизайнов, которые Вам
              понравились:</label
            >
            <div class="designs">
              <div
                class="design"
                v-for="cd of cards_designs"
                :key="`card_design${cd}`"
                @mouseenter="previewCardDesign = cd"
                @click="toggleCardDesign(cd)"
                :class="{
                  selected: form.selected_designs.find((x) => x == cd),
                }"
              >
                <img :src="`cards/${cd}`" />
              </div>
            </div>
          </div>
          <div class="col">
            <div class="cardPreviewHolder">
              <template v-if="previewCardDesign">
                <Tilt>
                  <div
                    class="cardPreview shadow"
                    :style="{
                      backgroundImage: `url(cards/${previewCardDesign})`,
                    }"
                  ></div>
                </Tilt>
              </template>
              <template v-else>
                <div class="cardPreview"></div>
              </template>
            </div>
          </div>
        </div>
        <br />
        <div class="row">
          <div class="col">
            <p>
              <label>Есть ли пожелания по дизайну:</label>
              <textarea v-model="form.design_desc" rows="3"></textarea>
            </p>
          </div>
          <div class="col">
            <label>Например,</label>
            <div class="example">
              Карта будет черной. А на центральной картинке должен быть дым или
              грейпфрут!
              <br />
              А цвет шрифта хочу чуть-чуть сероватый.
            </div>
          </div>
        </div>
      </div>
      <div class="question">
        <h4>Успех!</h4>
        <button @click="shareForm">Поделиться с Actid.Cards</button>
      </div>
    </template>
  </div>
</template>


<script>
import Tilt from "vanilla-tilt-vue";
import ThankYou from "./components/thankyou";

export default {
  name: "App",
  components: {
    Tilt,
    ThankYou,
  },
  data() {
    return {
      num: 0,
      cards_designs: [
        "1.jpg",
        "2.jpg",
        "3.jpg",
        "4.jpg",
        "5.jpg",
        "6.jpg",
        "7.jpg",
        "8.jpg",
        "9.jpg",
        "10.jpg",
        "11.jpg",
        "12.jpg",
        "13.jpg",
        "14.jpg",
        "15.jpg",
        "16.jpg",
        "17.jpg",
        "18.jpg",
      ],
      dataSent: false,
      previewCardDesign: "",
      form: {
        shop_name: "",
        lk_email: "",
        pos: "Poster",
        timing: "",
        loyality: "bonus",
        loyality_desc: "",
        loyality_discount: "50",
        logo_url: "",
        design_desc: "",
        selected_designs: [],
        instagram: "",
        yandex_maps: "",
        booking_phone: "",
      },
      ACRMForm: undefined,
    };
  },
  methods: {
    toggleCardDesign(cd) {
      if (this.form.selected_designs.includes(cd)) {
        this.form.selected_designs = this.form.selected_designs.filter(
          (c) => c !== cd
        );
      } else {
        this.form.selected_designs.push(cd);
      }
    },
    fillWithFakeData() {
      this.form.shop_name = "Магазин по продаже картинок";
      this.form.lk_email = "email@email.com";
      this.form.pos = "Poster";
      this.form.loyality = "bonus";
      this.form.loyality_desc = "При закрытии счёта получается бонус";
      this.form.loyality_discount = "50";
      this.form.logo_url = "https://actid.cards/logo.png";
      this.form.design_desc =
        "Карта будет черной. А на центральной картинке должен быть дым или грейпфрут!";
      this.form.selected_designs = ["1.jpg", "5.jpg"];
      this.form.instagram = "https://instagram.com/actid.cards";
      this.form.yandex_maps = "https://yandex.ru/maps/";
      this.form.booking_phone = "+7 (999) 999-99-99";
      this.form.timing = "Пн-Пт: 10:00 - 19:00, Сб-Вс: 10:00 - 18:00";
    },
    shareForm() {
      this.ACRMForm.sendData(this.form);
    },
    dataSuccessfullySent() {
      this.dataSent = true;
    },
    dataSendCanceled() {
      console.log("dataSendCanceled");
      this.dataSent = false;
    },
  },
  mounted() {
    if (document.location.href.includes("//localhost:"))
      this.fillWithFakeData();
    //
    this.ACRMForm = require("./components/ACRMFormFetcher.js")({
      formId: "qwertyujhgfdsxcvb",
      onSend: this.dataSuccessfullySent,
      onCancel: this.dataSendCanceled,
      debug: true,
    });
  },
};
</script>


<style>
@import "~hiq/dist/hiq.min.css";

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}

h3 {
  position: sticky;
  top: 0;
  z-index: 2;
  background: #ffffffbd;
  padding: 10px 0px;
  backdrop-filter: blur(4px);
}

.container {
  padding-top: 50px;
  padding-bottom: 150px;
  margin-left: 150px;
}

.question {
  margin-top: 20px;
  padding: 10px 20px;
  border-radius: 8px;
  border: 4px solid #f7f7f7;
  position: relative;
}

.example {
  font-size: 12px;
  color: #565656;
}

.designs {
  display: flex;
  flex-wrap: wrap;
  /* justify-content: center; */
  margin-top: 20px;
}
.designs .design {
  width: 94px;
  height: 74px;
  margin: 10px;
  background: #eee;
  border-radius: 5px;
  overflow: hidden;
  position: relative;
  cursor: pointer;
}

.designs .design.selected {
  box-shadow: 5px 5px 0px 0px #89cf37;
}

.design img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: 0px -20px;
}

.row {
  display: flex;
  flex-wrap: wrap;
  /* justify-content: center; */
}
.row .col {
  /* flex-basis: 50%; */
  flex-basis: 100%;
}

.row .col {
  padding-right: 10px;
}

/* last .col padding 0 */
.row .col:last-child {
  /* padding-right: 0; */
  padding-right: 0;
  position: absolute;
  right: -320px;
  width: 300px;
}

.cardPreviewHolder {
  background: #f7f7f7;
  height: 415px;
  margin-top: 0px;
  border-radius: 4px;
}
.cardPreviewHolder .cardPreview {
  background-color: #ddd;
  height: 380px;
  margin: 20px;
  position: relative;
  top: 20px;
  border-radius: 10px;
  background-size: 286px;
  background-position: -13px -80px;
}
.cardPreviewHolder .cardPreview.shadow {
  box-shadow: 0 12px 20px rgb(0 0 0 / 20%);
}
</style>

<style>
/* mobile media  */
@media (max-width: 767px) {
  .container {
    padding-top: 20px;
    margin: 0 auto;
  }
  h1 {
    font-size: 30px;
  }
  h3 {
    font-size: 20px;
  }
  .row {
    position: relative;
    overflow-x: scroll;
  }
  /* .row:after {
    content: "→";
    position: absolute;
    top: 5px;
    right: 0px;
    color: darkgray;
    height: 16px;
    width: 16px;
    text-align: center;
    font-size: 10px;
    border-radius: 50%;
  } */
  .row .col {
    padding-right: 0;
  }
  .row .col:last-child {
  }
  .designs {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    margin-top: 20px;
  }
  .designs .design {
    width: 100px;
    height: 82px;
  }

  .radio input[type="radio"]:checked + label::after,
  .radio input[type="radio"][aria-checked] + label::after {
    top: 8px;
  }

  .row::-webkit-scrollbar {
    height: 3px;
  }
  .row::-webkit-scrollbar-track {
    background: rgb(230, 230, 230);
  }
  .row::-webkit-scrollbar-thumb {
    background: rgb(156, 156, 156);
  }
}
</style>