module.exports = function InitACRMFormFetcher(params) {
    if (!params) return console.error('InitACRMFormFetcher: params object is not defined');
    if (!params.onSend) console.warn('InitACRMFormFetcher: params.onSend function is not defined');
    if (!params.formId) console.warn('InitACRMFormFetcher: params.formId string is not defined');
    if (params.debug) console.log('InitACRMFormFetcher: params', params);

    if (!params.dataHandler) {
        params.dataHandler = function (data) {
            return data;
        }
    }

    let _id = Math.random();
    let verify_window;

    let url = "https://acrm.actid.ru/verify/";
    if (params.debug) console.log("InitACRMFormFetcher URL", url);

    let session_history = [];

    window.addEventListener('message', function (e) {
        if (e.data.to != 'origin') return;
        if (e.data.id != _id) return;
        if (params.debug) console.log("- InitACRMFormFetcher verify_window message", e.data.id, e.data.msg);
        session_history.push(e.data.msg);

        if (e.data.msg == "beforeunload") {
            if (!session_history.includes('sent')) {
                if (params.onCancel) {
                    params.onCancel();
                }
            }
        }

        if (e.data.msg === 'sent') {
            verify_window.postMessage({ to: "verifier", id: _id, msg: "close" }, '*');
            if (params.onSend) params.onSend();
            return;
        }
    });

    return {
        sendData(form_data) {
            session_history = [];
            let data_to_share = {};
            data_to_share = params.dataHandler(form_data);
            if (!data_to_share) {
                return console.info('InitACRMFormFetcher: dataHandler returned false-like data and stopped form submission');
            }

            if (params.debug) console.log('InitACRMFormFetcher data_to_share after handler', data_to_share);

            let request_url = url + '#' + encodeURIComponent(JSON.stringify({
                _id,
                formId: params.formId,
                data: data_to_share
            }));
            if (params.debug) console.log('InitACRMFormFetcher request_url', request_url);

            verify_window = window.open(request_url, "Verify Window");
        },
        formId: params.formId
    }
};